import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
Song of the day (_Canción del día_): _mine_ by Kelly Clarkson

La agenda de hoy:
- Comenzar la 2<sup>a</sup> unidad (secciones A y B)

*******************************************************************************
# Unidad 2
| _Palabra_ | Word |
| ---- | ----- |
_un libro_ | a book
_una silla_ | a chair
_una computadora_ | a computer
_un escritorio_ | a desk
_un cuaderno_ | a notebook
_un lápiz_ | a pencil

## Los artículos indefinidos
- a/an: los artículos indefinidos
- a: si la palabra comienza en una consonante
- an: si la palabra comienza en una vocal
- El sonido es lo que cuenta

Ejemplos:
- an apple (una manzana)
- an exam (un examen)
- a university - "yu"
- an umbrella (_un paraguas_)
- an hour (una hora) - h muda
- an heir (un heredero) - h muda
- a historic event (un evento histórico)

| _Palabra_ | Word |
| ---- | ----- |
_diccionario_ | a dictionary
_papel_ | paper
_una hoja de papel_ | a sheet of paper
_pluma_ | a pen
_borrador_ | an eraser
_grapadora_ | a stapler 
_regla_ | a ruler

*******************************************************************************
## Prepositions

- in = _en_/_dentro de_
	- también se usa con los meses
- on = _en_/_sobre_/_encima de_
	- se usa con los días y las fechas
- under = _debajo de_

Ejemplos
- The book is on the desk = _El libro está encima del escritorio_
- The book is in the desk = _El libro está dentro del escritorio_
- The book is under the desk = _El libro está debajo del escritorio_

## Days of the Week

| Día | Day |
| --- | ---- |
| domingo | Sunday |
| lunes | Monday |
| martes | Tuesday |
| miércoles | Wednesday (wensday)|
| jueves | Thursday |
| viernes | Friday |
| sábado | Saturday |

- Christmas is in December
- Christmas is on Thursday
- My son was born in 2020
	- My son was born on May 3<sup>rd</sup>, 2020
*******************************************************************************
`



export default function Week01() {
	return (
		<div>
			<h1>Week 2 Lecture Notes (2/10)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
				<ul>
					<li><a href="https://quizlet.com/84184442/stationary-spanish-flash-cards/">Quizlet on stationery</a></li>
					<li><a href="https://www.britishcouncil.org.mx/blog/preposiciones">Preposiciones en inglés</a></li>
					<li><a href="https://grammar.collinsdictionary.com/us/english-easylearning-in-spanish/cuando-se-usan-a-o-an-en-ingles">A/An</a></li>
				</ul>
		</div>
	)
}
