import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home            from './components/Home'
import Contact         from './components/Contact'
import Navbar          from './components/Navbar'
import Error           from './components/Error'
import Footer          from './components/Footer'

import Blog            from './blog/Blog'
import Bluetooth       from './blog/Bluetooth'
import ESL             from './blog/ESL'
import Asahi           from './blog/Asahi'
import MacPreferences  from './blog/MacPreferences'
import Audio           from './blog/Audio'
import Mac             from './blog/Mac'
import Terminal        from './blog/Terminal'
import Dellxps         from './blog/Dellxps'
import Arch            from './blog/Arch'
import Spotify         from './blog/Spotify'
import Practices       from './blog/Practices'
import Scroll          from './blog/Scroll'
import Deskenv         from './blog/Deskenv'
import Preferences     from './blog/Preferences'
import Debian          from './blog/Debian'
import Virtualbox      from './blog/Virtualbox'
import Network         from './blog/Network'
import Browser         from './blog/Browser'
import Power           from './blog/Power'
import Tweaks          from './blog/Tweaks'
import Map             from './blog/Map'
import Bash            from './blog/Bash'
import Color           from './blog/Color'
import Sleep           from './blog/Sleep'

import Teaching   from './spr25/Teaching'
import Week01     from './spr25/Week01'
import Week02     from './spr25/Week02'

import Books           from './other/Books'

import ScrollToTop     from './components/ScrollToTop'



const message = `------------------------------------------------------------------
| ▗▖ ▗▖▗▄▄▖                                                      |
| ▐▌▗▞▘▐▌ ▐▌                                                     |
| ▐▛▚▖ ▐▛▀▚▖                                                     |
| ▐▌ ▐▌▐▌ ▐▌                                                     |
|                                                                |
| Hi there! Checking out the console messages?                   |
| I'd love to grab a coffee with you!                            |
| Please send me a note at krithika (at) krithravi (dot) com! :) |
------------------------------------------------------------------
`
          
          

function App() {
  console.log(message)
  return (
    <Router>
		<ScrollToTop/>
		<Navbar/>
		<div className="flexLayout">
			<Routes>
				<Route path='/'                      element={<Home/>} />
				<Route path='/contact'               element={<Contact/>} />

				<Route path='/blog'                  element={<Blog/>} />
				<Route path='/blog/bluetooth'        element={<Bluetooth/>} />
				<Route path='/blog/esl'              element={<ESL/>} />
				<Route path='/blog/asahi'            element={<Asahi/>} />
				<Route path='/blog/mac-preferences'  element={<MacPreferences/>} />
				<Route path='/blog/audio'            element={<Audio/>} />
				<Route path='/blog/mac'              element={<Mac/>} />
				<Route path='/blog/terminal'         element={<Terminal/>} />
				<Route path='/blog/dellxps'          element={<Dellxps/>} />
				<Route path='/blog/arch'             element={<Arch/>} />
				<Route path='/blog/spotify'          element={<Spotify/>} />
				<Route path='/blog/practices'        element={<Practices/>} />
				<Route path='/blog/scroll'           element={<Scroll/>} />
				<Route path='/blog/deskenv'          element={<Deskenv/>} />
				<Route path='/blog/preferences'      element={<Preferences/>} />
				<Route path='/blog/debian'           element={<Debian/>} />
				<Route path='/blog/virtualbox'       element={<Virtualbox/>} />
				<Route path='/blog/network'          element={<Network/>} />
				<Route path='/blog/browser'          element={<Browser/>} />
				<Route path='/blog/power'            element={<Power/>} />
				<Route path='/blog/tweaks'           element={<Tweaks/>} />
				<Route path='/blog/map'              element={<Map/>} />
				<Route path='/blog/bash'             element={<Bash/>} />
				<Route path='/blog/color'            element={<Color/>} />
				<Route path='/blog/sleep'            element={<Sleep/>} />

				<Route path='/teaching'              element={<Teaching/>} />
				<Route path='/teaching/week01'       element={<Week01/>} />
				<Route path='/teaching/week02'       element={<Week02/>} />


				<Route path='/books'                 element={<Books/>} />

				<Route path='*'                      element={<Error/>} />
			</Routes>
		</div>
		<div className="spacer"/>
		<Footer/>
    </Router>
  );
}

export default App;
