import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the spring 2025 session!
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
			</p>
			<ul>
				<li><Link to="/teaching/week01">Week 1 (week of 2/3)</Link></li>
				<li><Link to="/teaching/week02">Week 2 (week of 2/10)</Link></li>

			</ul>

		</div>
	)
}
